<template>
  <span>

    <!--Main Page-->
    <b-card>
      <div slot="header">
        <span class="d-flex w-100 justify-content-between">
          <span>
            <h5 style="margin-bottom: 0"><b>DIS Overview</b></h5>
          </span>
          <span style="display: inline-block; white-space: nowrap">
            Constellation:
            <b-form-select v-model="curConstellation" :options="constellations" style="width: fit-content"
                           @input="getDis()"></b-form-select>
          </span>
        </span>
      </div>
      <v-client-table v-if="curConstellation !== ''" style="width: 100%" :columns="dataTable.columns" :data="data"
                      :options="dataTable.options" :theme="dataTable.theme" class="dataTable">
        <span slot="h__deviceInfo.ipAddress"> IP Address</span>
        <span slot="h__edit" style="float: right">Start/Edit</span>
        <span slot="edit" slot-scope="props" style="display: inline-block; white-space: nowrap; float: right">
          <fa-icon :icon="['fas', 'pen-to-square']" size="lg" style="float: right; margin-left: 10px; cursor: pointer;"
                   @click="openEdit(props.row)"/>
          <router-link style="color: white" v-if="curConstellation"
                       :to="{ name: 'DIS Display', params: {conId: curConstellation._id, devId: props.row._id}}"
                       target="_blank">
            <fa-icon :icon="['fas', 'play']" style="float: right; cursor: pointer;"/>
          </router-link>
        </span>
        <span slot="images" slot-scope="props">{{ props.row.images.length }}</span>
        <span slot="h__deviceInfo.devicename">Name</span>
      </v-client-table>
      <p v-else style="text-align: center">Please select a constellation</p>
    </b-card>

      <!--Create DIS-->
    <b-modal scrollable title="Create DIS Device" class="modal-dark" v-model="create.modal" size="lg">
      <span class="d-flex w-100" style="flex-wrap: wrap;padding-top: 10px">
        <span style="font-size: large; margin-right: 5px; margin-left: 15px"><strong>Constellation: </strong></span>
        <b-form-select v-model="curConstellation" :options="constellations" style="width: fit-content"/>
        <span class="d-flex">
          <span style="font-size: large; margin-right: 5px; margin-left: 15px"><strong>Seconds: </strong></span>
          <b-form-input v-model="create.data.speed" type="number" id="input-small" style="width: 70px"/>
          <p style="margin-top: 18px;color: grey; font-size: 10px">(1 second or greater)</p>
        </span>
      </span>
      <br>
      <b-card bg-variant="dark" no-body header-tag="header" footer-tag="footer">
        <h5 slot="header" style="margin-bottom: 0"><strong>Device</strong></h5>
        <v-client-table v-if="curConstellation !== ''" style="width: 100%; padding: 10px"
                        :columns="create.dataTable.columns"
                        :data="create.devices" :options="create.dataTable.options" :theme="create.dataTable.theme"
                        class="dataTable">
        <div slot="status" slot-scope="props">
          <div v-if="props.row.status === 'active'">
            <b-badge :variant="'success'">{{ props.row.status }}</b-badge>
          </div>
          <div v-else-if="props.row.status === 'inactive'">
            <b-badge :variant="'danger'">{{ props.row.status }}</b-badge>
          </div>
        </div>
        <span slot="h__select"/>
        <div slot-scope="props" slot="select">
          <b-form-checkbox type="checkbox" v-model="create.data.deviceId" :value="props.row._id"
                           @click="update()"></b-form-checkbox>
        </div>
      </v-client-table>
      <p v-else style="text-align: center; margin-top: 10px">
          <span>Please select a constellation to select a device</span>
      </p>
      </b-card>
      <br>
      <b-card bg-variant="dark" no-body header-tag="header" footer-tag="footer">
        <div slot="header" class="d-flex w-100 justify-content-between">
          <h5 style="margin-bottom: 0"><b>Images</b></h5>
          <span style="display: inline-block; white-space: nowrap">
            <router-link style="color: white" v-if="curConstellation"
                         :to="{ name: 'ImageUpload', query: { constellation: curConstellation.url}}" target="_blank">
              <fa-icon :icon="['fas', 'plus']" size="lg"/>
            </router-link>
            <fa-icon v-if="edit.expandCard === true" :icon="['fas', 'down-left-and-up-right-to-center']" size="lg"
                     style="padding-left: 10px; cursor: pointer;" @click="create.expandCard = false"/>
            <fa-icon v-else-if="edit.expandCard === false" :icon="['fas', 'up-right-and-down0left-from-center']" size="lg"
                     style="padding-left: 10px; cursor: pointer;" @click="create.expandCard = true"/>
          </span>
        </div>
      <p v-if="images.length <= 0" style="text-align: center; margin-top: 10px">
          <span>No Images To Display</span>
      </p>
      <div v-else-if="create.expandCard === true">
        <div class="row" style=" margin: 10px">
          <b-card v-for="(image, index) in images" :key="index" no-body header-tag="header" bg-variant="dark"
                  style="margin-right: 10px; margin-bottom: 10px">
            <div slot="header" class="d-flex w-100 justify-content-between">
              <span class="d-flex w-100">
                <b-form-checkbox v-model="edit.data.images" :value="image.image" style="padding-left: 15px"/>
                <span v-if="image.name">{{ image.name }}</span>
                <span v-else>Unknown</span>
              </span>
              <router-link style="color: white"
                           :to="{ name: 'ImageUpload', query: { constellation: curConstellation.url, deviceId: image._id}}"
                           target="_blank">
                <fa-icon :icon="['fas', 'pen-to-square']" size="lg" style="cursor: pointer;"/>
              </router-link>
            </div>
            <b-card-img style="display: block; x-width:230px; max-height:270px; width: auto; height: auto" :src="getImage(image.image)"></b-card-img>
          </b-card>
        </div>
      </div>
      <div v-else style="overflow-x: auto; overflow-y: hidden;">
        <div class="row" style="min-width: max-content; margin: 10px">
          <b-card v-for="(image, index) in images" :key="index" no-body header-tag="header" bg-variant="dark"
                  style="margin-right: 10px; margin-bottom: 10px">
            <div slot="header" class="d-flex w-100 justify-content-between">
              <span class="d-flex w-100">
                <b-form-checkbox v-model="create.data.images" :value="image.image" style="padding-left: 15px"/>
                <span v-if="image.name">{{ image.name }}</span>
                <span v-else>Unknown</span>
              </span>
              <router-link style="color: white"
                           :to="{ name: 'ImageUpload', query: { constellation: curConstellation.url, deviceId: image._id}}"
                           target="_blank">
                <fa-icon :icon="['fas', 'pen-to-square']" size="lg" style="cursor: pointer;"/>
              </router-link>
            </div>
            <b-card-img style="display: block; x-width:230px; max-height:270px; width: auto; height: auto" :src="getImage(image.image)"></b-card-img>
          </b-card>
        </div>
      </div>
      </b-card>
      <template slot="modal-footer">
        <b-button size="sm" variant="secondary" @click="closeCreate()">Cancel</b-button>
        <b-button size="sm" variant="primary" :disabled="checkCreate()" @click="postDis()">Submit</b-button>
      </template>
    </b-modal>

      <!--Edit DIS-->
    <b-modal scrollable title="Edit DIS Device" class="modal-dark" v-model="edit.modal" size="xl">
      <b-row>
        <p style="font-size: large; margin-right: 5px; margin-left: 15px"><strong>Seconds: </strong></p>
        <b-form-input v-model="edit.data.speed" style="width: fit-content" type="number" id="input-small"
                      @change="update()"/>
        <p style="margin-top: 18px;color: grey; font-size: 10px">(1 second or greater)</p>
      </b-row>
      <br>
      <b-card bg-variant="dark" no-body header-tag="header" footer-tag="footer">
        <div slot="header" class="d-flex w-100 justify-content-between">
          <h5 style="margin-bottom: 0"><b>Images</b></h5>
          <span style="display: inline-block; white-space: nowrap">
            <router-link style="color: white" v-if="curConstellation"
                         :to="{ name: 'ImageUpload', query: { constellation: curConstellation.url}}" target="_blank">
              <fa-icon :icon="['fas', 'plus']"/>
            </router-link>
            <fa-icon v-if="edit.expandCard === true" :icon="['fas', 'down-left-and-up-right-to-center']" size="lg"
                     style="padding-left: 10px; cursor: pointer;" @click="edit.expandCard = false"/>
            <fa-icon v-else-if="edit.expandCard === false" :icon="['fas', 'up-right-and-down-left-from-center']" size="lg"
                     style="padding-left: 10px; cursor: pointer;" @click="edit.expandCard = true"/>
          </span>
        </div>
      <p v-if="images.length <= 0" style="text-align: center; margin-top: 10px">
          <span>No Images To Display</span>
      </p>
      <div v-else-if="edit.expandCard === true">
        <div class="row" style=" margin: 10px">
          <b-card v-for="(image, index) in images" :key="index" no-body header-tag="header" bg-variant="dark"
                  style="margin-right: 10px; margin-bottom: 10px">
            <div slot="header" class="d-flex w-100 justify-content-between">
              <span class="d-flex w-100">
                <b-form-checkbox v-model="edit.data.images" :value="image.image" style="padding-left: 15px"/>
                <span v-if="image.name">{{ image.name }}</span>
                <span v-else>Unknown</span>
              </span>
              <router-link style="color: white"
                           :to="{ name: 'ImageUpload', query: { constellation: curConstellation.url, deviceId: image._id}}"
                           target="_blank">
                <fa-icon :icon="['fas', 'pen-to-square']" size="lg"/>
              </router-link>
            </div>
            <b-card-img style="display: block; x-width:230px; max-height:270px; width: auto; height: auto" :src="getImage(image.image)"></b-card-img>
          </b-card>
        </div>
      </div>
      <div v-else style="overflow-x: auto; overflow-y: hidden;">
        <div class="row" style="min-width: max-content; margin: 10px">
          <b-card v-for="(image, index) in images" :key="index" no-body header-tag="header" bg-variant="dark"
                  style="margin-right: 10px; margin-bottom: 10px">
            <div slot="header" class="d-flex w-100 justify-content-between">
              <span class="d-flex w-100">
                <b-form-checkbox v-model="edit.data.images" :value="image.image" style="padding-left: 15px"/>
                <span v-if="image.name">{{ image.name }}</span>
                <span v-else>Unknown</span>
              </span>
              <router-link style="color: white"
                           :to="{ name: 'ImageUpload', query: { constellation: curConstellation.url, deviceId: image._id}}"
                           target="_blank">
                <fa-icon :icon="['fas', 'pen-to-sqaure']" size="lg"/>
              </router-link>
            </div>
            <b-card-img style="display: block; x-width:230px; max-height:270px; width: auto; height: auto" :src="getImage(image.image)"></b-card-img>
          </b-card>
        </div>
      </div>
      </b-card>
      <template slot="modal-footer">
        <b-button size="sm" variant="warning" style="width: fit-content;margin-right: auto" @click="areYouSureUnlink()">Unlink Device</b-button>
        <b-button size="sm" variant="secondary" @click="edit.modal = false">Cancel</b-button>
        <b-button size="sm" variant="primary" :disabled="checkEdit()" @click="putDis()">Submit</b-button>
      </template>
    </b-modal>

      <!--Are You Sure You Want To Unlink Device-->
    <b-modal no-close-on-backdrop scrollable title="Confirm Unlink" class="modal-dark" v-model="edit.areYouSureUnlink">
      <p><strong>If you unlink this device:</strong></p>
      <ul>
        <li>the device will be set to "unidentified"</li>
        <li>all set images will be unlinked from device</li>
        <li>all set images will not be deleted</li>
      </ul>
      <template slot="modal-footer">
        <b-button size="sm" variant="secondary" @click="edit.areYouSureUnlink = false">Cancel</b-button>
        <b-button size="sm" variant="warning" @click="deleteDis()">Unlink</b-button>
      </template>
    </b-modal>

  </span>
</template>

<script>
import constellation from '@/services/constellation';
import iss from '@/services/iss';
import blackhole from '@/services/blackhole';
import {ClientTable} from 'vue-tables-2';
import Vue from 'vue'
import constellationStore from "@/store/constellationStore";

Vue.use(ClientTable);

export default {

    name: "dis_overview",

    data() {
        return {
            data: [],
            dataTable: {
                columns: ['deviceInfo.friendlyName', 'deviceInfo.ipAddress', 'speed', 'images', 'edit'],
                options: {
                    orderBy: {column: 'order', ascending: true},
                    headings: {
                        speed: 'Speed',
                        images: 'Images',
                        edit: 'Edit',
                    },
                    sortable: ['deviceInfo.friendlyName', 'deviceInfo.ipAddress', 'images', 'speed'],
                    filterable: ['deviceInfo.friendlyName', 'deviceInfo.ipAddress', 'images', 'speed'],
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                useVuex: false,
                theme: 'bootstrap4',
                template: 'default',
            },
            curConstellation: "",
            create: {
                modal: false,
                expandCard: false,
                data: {
                    speed: 0,
                    deviceId: "",
                    images: [],
                },
                devices: [],
                dataTable: {
                    columns: ['select', 'friendlyName', 'ipAddress', 'devicetype', 'status'],
                    options: {
                        orderBy: {column: 'ipAddress', ascending: true},
                        headings: {
                            select: 'Select',
                            friendlyName: 'Name',
                            ipAddress: 'IP',
                            devicetype: 'Device Type',
                            status: 'Status',
                        },
                        sortable: ['devicename', 'ipAddress', 'devicetype', 'status'],
                        filterable: ['devicename', 'ipAddress', 'devicetype', 'status'],
                        sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                        pagination: {
                            chunk: 5,
                            edge: false,
                            nav: 'scroll'
                        },
                        perPage: 10,
                        skin: 'table table-striped table-hover',
                    },
                    useVuex: false,
                    theme: 'bootstrap4',
                    template: 'default',
                }
            },
            edit: {
                modal: false,
                expandCard: false,
                data: {
                    speed: 0,
                    _id: null,
                    images: [],
                },
                areYouSureUnlink: false,
            },
            constellations: [],
            images: [],
            upload: {
                count: 1,
                dataBlackhole: '',
                dataProfile: [],
                modal: false,
            },

        }
    },

    methods: {
        getConstellations() {
            constellationStore.dispatch('getConstellations').then(response1 => {
                response1.forEach(constellation => {
                    this.constellations.push({
                        value: {
                            url: constellation.url + ":" + constellation.port,
                            _id: constellation._id
                        },
                        text: constellation.constellationname
                    });
                    this.constellations.sort((a, b) => {
                        return a.text.localeCompare(b.text)
                    });
                });
            });
            this.constellations.sort((a, b) => {
                return a.text.localeCompare(b.text)
            })
        },
        getImage(id) {
            return blackhole.readImage(id);
        },
        getDis() {
            this.data = [];
            constellation.readAllDIS(this.curConstellation.url).then(response => {
                if (Array.isArray(response.data)) {
                    this.getImages();
                    response.data.forEach(i => {
                        i.ip = this.curConstellation.url;
                        this.data.push(i);
                    })
                }
            });
            if (this.create.modal) {
                this.getDevices();
            }
        },
        deleteDis() {
            this.data = [];
            constellation.deleteDIS(this.curConstellation.url, this.edit.data._id).then(() => {
                this.closeEdit();
                this.edit.areYouSureUnlink = false;
                this.getDis()
            });
        },
        getDevices() {
            //TODO Add to store
            constellation.readUnidentifiedDevices(this.curConstellation.url).then(response => {
                if (Array.isArray(response.data)) {
                    this.create.devices = response.data;
                }
            });
            this.create.data.speed = null;
            this.create.data.deviceId = "";
            this.create.data.images = [];

        },
        getImages() {
            constellation.readDISImages(this.curConstellation.url).then(response => {
                if (Array.isArray(response.data)) {
                    this.images = response.data;
                }
            });
        },
        openEdit(device) {
            this.getImages();
            this.edit.modal = true;
            this.edit.data.speed = device.speed;
            this.edit.data._id = device._id;
            this.edit.data.images = device.images;
        },
        putDis() {
            let data = {speed: this.edit.data.speed, images: this.edit.data.images};
            constellation.updateDISDevice(this.curConstellation.url, this.edit.data._id, data).then(() => {
                this.closeEdit();
                this.getDis();
            });
        },
        postDis() {
            constellation.createDIS(this.curConstellation.url, this.create.data).then(() => {
                this.closeCreate();
                this.getDis();
            });
        },
        closeEdit() {
            this.edit.modal = false;
            this.edit.data.speed = null;
            this.edit.data._id = null;
            this.edit.data.images = [];
        },
        checkEdit() {
            if (this.edit.data.speed >= 1) {
                return (false);
            } else {
                return (true);
            }
        },
        checkCreate() {
            if (this.create.data.deviceId.length > 1 && this.create.data.speed >= 1) {
                return (false);
            } else {
                return (true);
            }
        },
        closeCreate() {
            this.create.modal = false;
            this.create.data.speed = null;
            this.create.data.deviceId = "";
            this.create.data.images = [];

        },
        openCreate() {
            this.getImages();
            this.create.data.speed = null;
            this.create.data.deviceId = "";
            this.create.data.images = [];
            this.create.modal = true;
            this.getDevices()
        },
        areYouSureUnlink() {
            this.edit.areYouSureUnlink = true;
        },
        update() {
            this.$forceUpdate()
        },
    },

    created() {
        this.getConstellations();
    },
}
</script>

<style scoped>
.scrollbar {
    margin-left: 30px;
    float: left;
    height: 300px;
    width: 65px;
    background: #F5F5F5;
    overflow-y: scroll;
    margin-bottom: 25px;
}

</style>